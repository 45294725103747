import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OktaAuthStateService } from "@okta/okta-angular";
import { AuthState } from "@okta/okta-auth-js";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, filter, map } from "rxjs";
import { UserPayload } from "src/app/models/user-payload.model";
import { UserService } from "src/app/services/user/user.service";
import { UtilityService } from "src/app/services/utility/utility.service";

@Injectable()
export class LoginAuthGuard {

    public isAuthenticated$!: Observable<boolean>;
    public isAuthUser: Boolean = false;
    public userPayload: UserPayload = { Email: '' };

    constructor(
        private _router: Router,
        private _oktaAuthStateService: OktaAuthStateService,
        private userService: UserService,
        private permissionsService: NgxPermissionsService,
        private utilityService: UtilityService
    ) { }

    canActivate() {
        this.isAuthenticated$ = this._oktaAuthStateService.authState$.pipe(
            filter((s: AuthState) => !!s),
            map((s: AuthState) => s.isAuthenticated ?? false)
        );

        this.isAuthenticated$.subscribe(data => {
            this.isAuthUser = data;
            if (this.isAuthUser) {

                let claims = this._oktaAuthStateService.authState$.pipe(
                    filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
                    map((authState: AuthState) => authState.idToken?.claims));

                claims.subscribe(data => {
                    this.userPayload.Email = data?.email || '';

                    let loggedInUserJson = localStorage.getItem("loggedInUser");
                    let permissionJson = localStorage.getItem('userPermission');

                    if (loggedInUserJson && permissionJson) {
                        this.permissionsService.loadPermissions(JSON.parse(permissionJson));
                        this.handleNavigation();
                    }

                    else {
                        this.userService.saveUserInfo(this.userPayload).subscribe((data) => {
                            if (data) {
                                this.utilityService.setUserInfo(data);
                                this.userService.getUserPermission().subscribe((permissions) => {
                                    if (permissions) {
                                        localStorage.setItem("userPermission", JSON.stringify(permissions));
                                        this.permissionsService.loadPermissions(permissions);
                                        this.handleNavigation();
                                    }
                                });
                            }
                        });
                    }
                });
            }
        })
    }

    handleNavigation() {
        let loggedInUserJson = localStorage.getItem("loggedInUser");
        if (loggedInUserJson) {
            let loggedInUser = JSON.parse(loggedInUserJson);
            if (loggedInUser && loggedInUser.isAcceptedTC) {
                this._router.navigate(['/dull-list']);
            } else {
                this._router.navigate(['/terms-of-use']);
            }
        }
    }
}