import { Component, OnInit } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AngularDeviceInformationService } from 'angular-device-information';
import { AlertController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState, AuthStateManager } from '@okta/okta-auth-js';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {

  public isAuthenticated$!: Observable<boolean>;

  constructor(
    private swUpdate: SwUpdate,
    public router: Router,
    private _oktaAuthStateService: OktaAuthStateService,
    private permissionsService: NgxPermissionsService,
    public alertController: AlertController) {
    this.checkVersionUpdates();
  }

  public ngOnInit() {
    this.isAuthenticated$ = this._oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false),
    );
    this.isAuthenticated$.subscribe(data => {
      let loggedInUserJson = localStorage.getItem("loggedInUser");
      let permissionJson = localStorage.getItem('userPermission');

      if (loggedInUserJson && permissionJson) {
        this.permissionsService.loadPermissions(JSON.parse(permissionJson));
      }
    });
  }

  public checkVersionUpdates() {
    this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))).subscribe((rs) => {
        if (rs.type == 'UPDATE_AVAILABLE') {
          this.alertController.create({
            header: `Digital Dull Grading: Loading New Version`,
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  window.location.reload();
                }
              },
            ],
            backdropDismiss: false,
          }).then(res => {
            res.present();
          });
        }
      });
  }
}