export class AppConstants {
   static readonly dullListItemsPerPage = 20;
   static readonly CollectionType = ['Dull', 'DyePen', 'PostRepair'];
}

export class Roles {
   static readonly AMO = 'AMO';
   static readonly Default = 'Default'
}

export const MessagesConstants = {
   supportEmailId: "DDGFeedback@bakerhughes.com",
   mailSubject: 'Service request',
   emailBody: `** Thank you for reaching out to our support team. Please provide detailed queries or issues and any testing evidence to assist us in better understanding and resolving your issue. **`
}

export const ImageUploadStatus = {
   notUploaded: 1,
   uploaded: 2,
   notDrillBit: 3,
   adultContent: 4,
   uploadFailed: 5,
   processing: 6,
}

export const RetryConst = {
   retryAttempt: 2,
   delayLimit: 10000, // 10s  
}

export const DefaultCameraSubStrings = ['Back Triple Camera', 'Logitech Webcam', 'Back Camera', '0, facing back', 'Integrated'];

export const DullEventStatus = {
   draft: 1,
   completed: 2,   
}
