import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DullEventModel, DullImageModel, ImageCollection } from 'src/app/models/dull-event.data-engine';
import { ErrorResponse } from 'src/app/models/error-reponse.model';
import { UserInfo } from 'src/app/models/user-info.model';
import { AppConstants } from 'src/app/shared/constants/app.constants'

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public myDullEvent: DullEventModel = <DullEventModel>{};
  public currentImage: DullImageModel = <DullImageModel>{};
  public currentImageNo: number = 0;
  public imageIndex: number = 0;
  public popStatus: boolean = false;

  private dullEventId: string = '';
  private selectedTab: string = '';
  public userInfo = new Subject<UserInfo>();
  public errorResponse: ErrorResponse = <ErrorResponse>{};

  public setDullEvent(myDullEvent: DullEventModel) {
    this.myDullEvent = myDullEvent;
    this.dullEventId = this.myDullEvent.id;
  }

  public getDullEvent() {
    return this.myDullEvent;
  }

  public setCurrentImage(image: DullImageModel) {
    this.currentImage = image;
    this.currentImageNo = this.currentImage.imageNumber - 1;
  }

  public setCurrentImageByIndex(index: number) {
    this.currentImage = this.getImageCollection().images[index];
    this.currentImageNo = index;
    return this.currentImage;
  }

  public setCurrentImageByBladeIndexImageIndex(bladeIndex: number, imageIndex: number) {
    this.currentImage = this.myDullEvent.imageCollection[bladeIndex].images[imageIndex];
    return this.currentImage;
  }

  public getCurrentImage() {
    return this.currentImage;
  }

  public getImageCollection() {

    let collection: ImageCollection = new ImageCollection();

    if (this.getSelectedTab() == AppConstants.CollectionType[0]) {
      this.myDullEvent.imageCollection.forEach((aCollection) => {
        aCollection.images.forEach((aImage) => {
          if (aImage.id == this.currentImage.id) {
            collection = aCollection;
          }
        });
      });
    }

    else if (this.getSelectedTab() == AppConstants.CollectionType[1]) {
      this.myDullEvent.dyePenImageCollection.images.forEach((aImage) => {
        if (aImage.id == this.currentImage.id) {
          collection = this.myDullEvent.dyePenImageCollection;
        }
      });
    }

    else if (this.getSelectedTab() == AppConstants.CollectionType[2]) {
      this.myDullEvent.postRepairImageCollection.images.forEach((aImage) => {
        if (aImage.id == this.currentImage.id) {
          collection = this.myDullEvent.postRepairImageCollection;
        }
      });
    }

    return collection;
  }

  public getCurrentImageNo() {
    return this.currentImageNo;
  }

  public setDullEventId(dullEventId: string) {
    this.dullEventId = dullEventId;
  }

  public getDullEventId() {
    return this.dullEventId;
  }

  public setImageIndex(imageIndex: number) {
    this.imageIndex = imageIndex;
  }

  public getImageIndex() {
    return this.imageIndex;
  }

  public setSelectedTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  public getSelectedTab() {
    return this.selectedTab;
  }

  public setUserInfo(user: UserInfo) {
    localStorage.setItem("loggedInUser", JSON.stringify(user));
    this.userInfo.next(user);
  }

  public getUserInfo(): Observable<UserInfo> {
    let loggedInUser = localStorage.getItem("loggedInUser");

    if (loggedInUser) {
      this.userInfo.next(JSON.parse(loggedInUser));
    }

    return this.userInfo.asObservable();
  }

  public setErrorResponse(errorResponse: ErrorResponse) {
    this.errorResponse = errorResponse;
  }

  public getErrorResponse() {
    return this.errorResponse;
  }
}