import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { UserInfo } from 'src/app/models/user-info.model';
import { UserPayload } from 'src/app/models/user-payload.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public saveUserInfo(data: UserPayload): Observable<UserInfo> {
    return this.http
      .post<UserInfo>(this.apiUrl + 'User/Save', data);
  }

  public getUserPermission(): Observable<string[]> {
    return this.http
      .get<string[]>(this.apiUrl + 'User/Permission',);
  }

  public acceptTC(): Observable<boolean> {
    return this.http.patch<boolean>(this.apiUrl + 'User/AcceptTnC',null);
  }
}