//  //these need to be removed / replaced by 
export interface CreateDullInfo {
    serialNumber?: string,
    workOrderNumber?: string,
    dullEventId?: string;
}

//basically an interface consumed by src/app/models/dull-event.data-engine  Dull class
export class IDull {
    public id?: string;
    public serialNumber?: string;
    public partNumber: string = <string>{}
    public workOrderNumber?: string;
    public isDeleted: boolean = false;
    public createdUserId?: number;
    public createdUser?: string;
    public createdDate?: string;
    public modifiedUserId?: number;
    public modifiedDate?: string;
    public bitSize: number = 0;
    public bladeCount: number = 0;
    public bitStyle: string = <string>{};
    public status?: number;
    public isAMOEvent: boolean = false;
    public enableDelete: boolean = false;
}