import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { ErrorResponse } from 'src/app/models/error-reponse.model';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private loadingService: LoaderService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private utilityService: UtilityService,
    private router: Router,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.match(/DullImage/)) {
      return next.handle(this.addAuthHeaderToAllowedOrigins(request));
    }
    this.totalRequests++;
    this.loadingService.setLoading(true);
    return next.handle(this.addAuthHeaderToAllowedOrigins(request)).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (response != null && response.url?.includes('GetDullByDullEventId')) {
            if (!response.body.isSuccess && (response.body.header != null ) && response.body.message != null) {
              let errorResponse: ErrorResponse = {
                header: response.body.header,
                message: response.body.message
              };
              this.utilityService.setErrorResponse(errorResponse);
              this.loadingService.setLoading(false)
              this.router.navigate(['/error-page']);
            }
          }
        }
      }),
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false);
        }
      }),
      catchError(err => {
        if ([401].includes(err.status)) {
          this._oktaAuth.signOut();
          localStorage.clear();
        }
        const error = err.error?.message || err.statusText;
        return throwError(() => error);
      }));
  }

  private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let req = request;
    const authToken = this._oktaAuth.getAccessToken();
    const tokenInfo = this._oktaAuth.tokenManager.getTokens().then(x => x.accessToken).then(x => x?.accessToken);
    req = request.clone({ setHeaders: { 'Authorization': `Bearer ${authToken}` } });
    return req;
  }
}