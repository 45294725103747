import { Injector, NgModule, isDevMode } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { ServiceLocator } from './models/locator.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularDeviceInformationService } from 'angular-device-information';


import {
  OktaAuthModule,
  OktaConfig
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from './shared/shared.module';


const authConfig = {
  issuer: environment.oktaIssuer,
  clientId: environment.oktaClientId,
  redirectUri: window.location.origin + '/login/callback'
}
const oktaAuth = new OktaAuth(authConfig);
const moduleConfig: OktaConfig = { oktaAuth };
let deviceversion: number = 0;

@NgModule({
  declarations: [AppComponent, SpinnerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgSelectModule,
    HammerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // ServiceWorkerModule.register('../ngsw-worker.js', {
    //   enabled: environment.production && deviceversion < 17
    // }),
    HttpClientModule,
   OktaAuthModule.forRoot(moduleConfig),
    NgxPermissionsModule.forRoot(),
    SharedModule
  ],
  exports: [
    SpinnerComponent
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy,}, 
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,}, 
    AngularDeviceInformationService],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector, private device: AngularDeviceInformationService) {    // Create global Service Injector.
    ServiceLocator.injector = this.injector;
    deviceversion = device.getDeviceInfo().osVersion;
  }
}

