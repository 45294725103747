import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { LoginAuthGuard } from './guards/login.guard';
import { ComponentLibraryModule } from '@bh-digitalsolutions/ui-toolkit-angular/dist';
import { PageAuthGuard } from './guards/page.guard';

@NgModule({
  declarations: [
    AppHeaderComponent,
    ConfirmationPopupComponent
  ],
  imports: [
    CommonModule,
    ComponentLibraryModule
  ],
  exports: [
    AppHeaderComponent,
    ConfirmationPopupComponent
  ],
  providers: [LoginAuthGuard, PageAuthGuard]
})
export class SharedModule { }
