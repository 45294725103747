import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadResponse } from 'src/app/models/upload-response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DullImageService {

  apiUrl: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  public uploadImageToBlob(data: any): Observable<UploadResponse> {
    return this.httpClient.post<UploadResponse>(this.apiUrl + 'DullImage/Save', data);
  }

  public getDullImage(request: any): Observable<UploadResponse> {
    return this.httpClient.post<UploadResponse>(this.apiUrl + 'DullImage/GetImage' , request);
  }

  public deleteAdditionalImage(request: any) {
    return this.httpClient.patch(this.apiUrl + 'DullImage/DeleteAdditionalImage', request);
  }
}
