import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: 'confirmation-popup.component.html',
  styleUrls: ['confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent implements OnInit {
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();
  @Output() onACReponse: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public alertController: AlertController
  ) { }

  ngOnInit() { }

  public async dialog(message?: string, header?: string, confirmText?: string) {
    if (message == null || header == null || confirmText == null) {
      header = 'Update Dull Grade?';
      message = 'The Dull Grade is already marked as complete. Do you want to go ahead and update it?';
      confirmText = 'Update';
    }

    this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: confirmText,
          cssClass: confirmText == 'Delete' ? 'alert-button-confirm button-delete-color' : 'alert-button-confirm button-confirm-color',
          handler: () => {
            this.onUpdate.emit(true);
          }
        },
        {
          text: 'Cancel',
          cssClass: 'alert-button-cancel',
          handler: () => {
            this.onUpdate.emit(false);
          }
        },
      ],
      backdropDismiss: true,
    }).then(res => {
      res.present();
    });
  }

  public async adultContentAlert() {

    this.alertController.create({
      header: 'Upload failed. Capture a valid drill bit image.',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.onACReponse.emit(true);
          }
        },
      ],
      backdropDismiss: false,
    }).then(res => {
      res.present();
    });

  }
}
